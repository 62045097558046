import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { Task } from '@appTypes/task.interface';
import { ApiService } from '@core/services/api.service';

@Injectable()
export class TaskDetailResolver {
  public constructor(
    private apiService: ApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Task> {
    return from(
      this.apiService.client.tasks.getDetail.query(Number(route.params.taskId))
    ).pipe(
      catchError((e) => {
        this.notificationService.showError(
          'Objednávka nenalezena nebo nastala chyba'
        );

        throw e;
      })
    );
  }
}
