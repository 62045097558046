<app-dialog-title
  [title]="'admin.customers.editCustomer' | translate"
  icon="edit"
/>

<form [formGroup]="editCustomerForm" (ngSubmit)="onEditCustomer()">
  <div mat-dialog-content>
    <div class="responsive-form">
      <app-text-input [required]="true" id="firstName" label="global.firstName" [formControl]="firstNameFormControl"></app-text-input>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.lastName' | translate}}</mat-label>
        <input id="lastName" required type="text" matInput [formControl]="lastNameFormControl">
        <mat-error *ngIf="lastNameFormControl.hasError('required')">
          {{'global.requiredValue' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.email' | translate}}</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" placeholder="jan.novak@seznam.cz">
        <mat-error *ngIf="emailFormControl.hasError('email')">
          {{'global.invalidEmail' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.phone' | translate}}</mat-label>
        <lac-mat-country-selector matPrefix [selectorFor]="input"
                                  [preferredCountries]="['cz', 'sk']"></lac-mat-country-selector>
        <lac-mat-tel-input id="phone" #input [formControl]="phoneFormControl"></lac-mat-tel-input>
      </mat-form-field>
    </div>

    <fieldset>
      <legend>{{'admin.customers.billingLegend' | translate}}</legend>

      <div class="responsive-form">
        <mat-form-field class="w-full" >
          <mat-label>{{'global.in' | translate}}</mat-label>
          <input id="in" type="text" matInput [formControl]="inFormControl">

          <button
            matSuffix
            mat-icon-button
            type="button"
            [disabled]="aresLoading"
            (click)="loadAresValuesClick()"
            [matTooltip]="'auth.signup.loadFromAres' | translate"
          >
            <mat-icon *ngIf="!aresLoading">
              autorenew
            </mat-icon>
            <mat-icon *ngIf="aresLoading">
              <mat-spinner diameter="24"></mat-spinner>
            </mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="w-full" >
          <mat-label>{{'global.tin' | translate}}</mat-label>
          <input id="tin" type="text" matInput [formControl]="tinFormControl">
        </mat-form-field>

        <mat-form-field class="w-full" >
          <mat-label>{{'global.companyName' | translate}}</mat-label>
          <input id="companyName" type="text" matInput [formControl]="companyNameFormControl">
        </mat-form-field>

        <mat-form-field class="w-full" >
          <mat-label>{{'global.address' | translate}}</mat-label>
          <input id="address" type="text" matInput [formControl]="addressFormControl">
        </mat-form-field>

        <mat-form-field class="w-full" >
          <mat-label>{{'global.city' | translate}}</mat-label>
          <input id="city" type="text" matInput [formControl]="cityFormControl">
        </mat-form-field>

        <mat-form-field class="w-full" >
          <mat-label>{{'global.zip' | translate}}</mat-label>
          <input id="zip" type="text" matInput [formControl]="zipFormControl">
        </mat-form-field>
      </div>
    </fieldset>
  </div>
  <div mat-dialog-actions class="flex justify-between">
    <button mat-raised-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>

    <button mat-raised-button color="primary" [disabled]="loading || !editCustomerForm.valid" cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner></mat-spinner>
      </mat-icon>
      <mat-icon *ngIf="!loading">edit</mat-icon>
      {{'global.edit' | translate}}
    </button>
  </div>
</form>

