<mat-toolbar class="header">
  <mat-toolbar-row>
    <h2 *ngIf="breadcrumbItem">
      {{ breadcrumbItem.label | translate }}
    </h2>

    <span class="spacer"></span>

    <button
      class="hidden"
      matBadge="15"
      matBadgeColor="warn"
      mat-icon-button
      matTooltip="Notifikace"
      matBadgePosition="below"
      [matMenuTriggerFor]="notificationsMenu"
    >
      <mat-icon>notifications</mat-icon>
    </button>

    <mat-menu #notificationsMenu="matMenu">
      <mat-list>
        <h3 mat-subheader>Notifications</h3>
        <!--<mat-list-item *ngFor="let notification of getNotifications() | async">
          {{notification}}
        </mat-list-item>-->
      </mat-list>
    </mat-menu>

    <mat-divider class="ml-1 mr-1 hidden lg:block" vertical></mat-divider>

    <ng-container *ngIf="false">
      <app-language-selector class="mr-2"></app-language-selector>
    </ng-container>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="block lg:hidden">
        <a mat-menu-item [routerLink]="['/admin/dashboard']">
          <mat-icon>home</mat-icon>
          {{ 'breadcrumbs.dashboard' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/admin/customer']">
          <mat-icon>people</mat-icon>
          {{ 'dashboard.customers' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/admin/task/calendar']">
          <mat-icon>event</mat-icon>
          {{ 'breadcrumbs.calendar' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/admin/task/table']">
          <mat-icon>list_alt</mat-icon>
          {{ 'breadcrumbs.orders' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/admin/warehouse']">
          <mat-icon>warehouse</mat-icon>
          {{ 'breadcrumbs.warehouses' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/admin/sms']">
          <mat-icon>sms</mat-icon>
          {{ 'header.sms' | translate }}
        </a>

        <mat-divider></mat-divider>
      </div>

      <a mat-menu-item [routerLink]="['/admin/account']">
        <mat-icon>settings</mat-icon>
        {{ 'header.accountSettings' | translate }}
      </a>
      <a
        tourAnchor="branch-list-achor-reservation"
        mat-menu-item
        [routerLink]="['/reservation/' + getBranchUrl()]"
        target="_blank"
      >
        <mat-icon>open_in_new</mat-icon>
        {{ 'admin.branchStand.orderForm' | translate }}
      </a>

      <a mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        {{ 'header.logout' | translate }}
      </a>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
