<form [formGroup]="breakHoursForm" [class.phone]="device === 'phone'">
  <table class="w-full text-center">
    <tr>
      <td>
        {{'shortDays.mo' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="mondayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="mondayBreakHoursEndFormControl">
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.tu' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="tuesdayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="tuesdayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.we' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="wednesdayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="wednesdayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.th' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="thursdayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="thursdayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.fr' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="fridayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="fridayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.sa' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="saturdayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="saturdayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.su' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="sundayBreakHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="sundayBreakHoursEndFormControl" >
        </mat-form-field>
      </td>
    </tr>
  </table>
</form>
