<mat-form-field  class="w-full">
  <mat-label>{{'admin.tire-manufacturer-selector.tireManufacturer' | translate}}</mat-label>
  <mat-select required [formControl]="valueFormControl">
    <mat-option>
      <ngx-mat-select-search
        [clearSearchInput]="false"
        ngModel
        (ngModelChange)="filterChange($event)"
        [placeholderLabel]="'global.search' | translate"
        [noEntriesFoundLabel]="'global.notFound' | translate"
        [searching]="this.loading"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngIf="defaultSelectValue" [value]="defaultSelectValue.key">
      {{defaultSelectValue.label}}
    </mat-option>

    <mat-option *ngFor="let item of selectValues" [value]="item.key">
      {{item.label}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="valueFormControl.hasError('required')">
    {{'global.requiredValue' | translate}}
  </mat-error>
  <mat-spinner matSuffix [diameter]="18" *ngIf="loading" class="right-static-suffix"></mat-spinner>
</mat-form-field>
