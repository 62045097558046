import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface BreadcrumbItem {
  path: string;
  label: string;
  icon?: string;
  outlet?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [];
  private _breadcrumbItems: BreadcrumbItem[] = [];

  public constructor(private route: ActivatedRoute, private router: Router) {}

  public ngOnInit(): void {
    for (const activatedRoute of this.route.pathFromRoot) {
      if (activatedRoute.snapshot.data.breadcrumb) {
        let path = '';

        for (const [index, segment] of activatedRoute.snapshot.url.entries()) {
          if (activatedRoute.snapshot.url.length === 1) {
            path += `/${segment.path}`;
          } else {
            if (index + 1 < activatedRoute.snapshot.url.length) {
              path += segment.path;
            } else {
              path += `/${segment.path}`;
            }
          }
        }
        if (activatedRoute.snapshot.outlet === 'primary') {
          this.breadcrumbItems.push({
            path,
            label: activatedRoute.snapshot.data.breadcrumb.label,
            icon: activatedRoute.snapshot.data.breadcrumb.icon
          });
        } else {
          this.breadcrumbItems.push({
            path,
            label: activatedRoute.snapshot.data.breadcrumb.label,
            icon: activatedRoute.snapshot.data.breadcrumb.icon,
            outlet: activatedRoute.snapshot.outlet
          });
        }
      }
    }

    this.prepareItemsToRender();

    // duplicates
    this.breadcrumbItems = [
      ...new Map(
        this.breadcrumbItems.map((item, key) => [item.path, item])
      ).values()
    ];
  }

  private prepareItemsToRender(): void {
    this._breadcrumbItems = JSON.parse(JSON.stringify(this.breadcrumbItems));

    for (const [index, item] of this.breadcrumbItems.entries()) {
      if (!item.outlet) {
        if (index === 0) {
          item.path = this.router.createUrlTree([item.path]).toString();
        } else {
          const previousPaths = this.getPreviousPaths(index);
          previousPaths.push(item.path);

          item.path = this.router
            .createUrlTree([previousPaths.join('')])
            .toString();
        }
      } else {
        const previousPaths = this.getPreviousPaths(index);
        const slugs = item.path.split('/');
        item.path = this.router
          .createUrlTree([
            previousPaths.join(''),
            { outlets: { [item.outlet]: slugs } }
          ])
          .toString();
      }
    }
  }

  private getPreviousPaths(_index: number): string[] {
    const paths: string[] = [];

    for (const [index, item] of this._breadcrumbItems.entries()) {
      if (index < _index) {
        paths.push(item.path);
      }
    }

    return paths;
  }
}
