<form [formGroup]="customerCarForm" class="customer-car-form">
  <mat-form-field  class="w-full">
    <mat-label>{{'admin.customer-car-selector.customerCar' | translate}}</mat-label>
    <input type="text"
           id="customer"
           [placeholder]="'admin.customer-car-selector.placeholder' | translate"
           required
           matInput
           [formControl]="customerCarFormControl"
           [matAutocomplete]="auto">
    <mat-error *ngIf="customerCarFormControl.hasError('required')">
      {{'global.requiredValue' | translate}}
    </mat-error>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <button
      mat-icon-button
      matSuffix

      (click)="$event.stopPropagation(); openEditCustomerCarDialog()"
      [matTooltip]="'admin.customers.editCustomerCars' | translate"
      *ngIf="!loading && customer">
      <mat-icon>edit</mat-icon>
    </button>
    <mat-spinner matSuffix [diameter]="18" *ngIf="loading" class="right-static-suffix"></mat-spinner>
  </mat-form-field>
</form>
