<h1 mat-dialog-title class="text-center">
  {{'auth.reset-password.title' | translate}}
</h1>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onRequestResetPassword()">
  <div mat-dialog-content>
    <app-text-input
      id="email"
      type="email"
      label="global.email"
      autocomplete="username"
      [required]="true"
      [formControl]="emailFormControl"
    >
    </app-text-input>
  </div>
  <div mat-dialog-actions class="justify-between">
    <button mat-raised-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>
    <button mat-raised-button color="primary" [disabled]="loading || !resetPasswordForm.valid" cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner></mat-spinner>
      </mat-icon>
      {{'auth.reset-password.submit' | translate}}
    </button>
  </div>
</form>

