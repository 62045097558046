import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { unifyPhraseString } from '../../utils';

@Injectable()
export class WarehouseApiService {
  public constructor(private translateService: TranslateService) {}
  private translateLayer(phrase: string): string {
    const phraseChunks = phrase.split(',');
    let phraseResult = '';

    // state
    const stateActive = unifyPhraseString(
      this.translateService.instant('admin.warehouses.states.ACTIVE')
    );
    const stateInactive = unifyPhraseString(
      this.translateService.instant('admin.warehouses.states.INACTIVE')
    );

    // diskType
    const diskTypeNone = unifyPhraseString(
      this.translateService.instant('admin.warehouses.diskTypes.NONE')
    );
    const diskTypeTinny = unifyPhraseString(
      this.translateService.instant('admin.warehouses.diskTypes.TINNY')
    );
    const diskTypeAlu = unifyPhraseString(
      this.translateService.instant('admin.warehouses.diskTypes.ALU')
    );

    // tireType
    const tireTypeWinter = unifyPhraseString(
      this.translateService.instant('admin.warehouses.tireTypes.WINTER')
    );
    const tireTypeSummer = unifyPhraseString(
      this.translateService.instant('admin.warehouses.tireTypes.SUMMER')
    );
    const tireTypeBoth = unifyPhraseString(
      this.translateService.instant('admin.warehouses.tireTypes.BOTH')
    );

    // accessories
    const accessoriesWheelScrews = unifyPhraseString(
      this.translateService.instant(
        'admin.warehouses.allAccessories.WHEEL_SCREWS'
      )
    );
    const accessoriesSafetyScrew = unifyPhraseString(
      this.translateService.instant(
        'admin.warehouses.allAccessories.SAFETY_SCREW'
      )
    );
    const accessoriesCover = unifyPhraseString(
      this.translateService.instant('admin.warehouses.allAccessories.COVER')
    );
    const accessoriesPressureSensors = unifyPhraseString(
      this.translateService.instant(
        'admin.warehouses.allAccessories.PRESSURE_SENSORS'
      )
    );
    const accessoriesSpacerRings = unifyPhraseString(
      this.translateService.instant(
        'admin.warehouses.allAccessories.SPACER_RINGS'
      )
    );
    const accessoriesMatrix = unifyPhraseString(
      this.translateService.instant('admin.warehouses.allAccessories.MATRIX')
    );
    const accessoriesNone = unifyPhraseString(
      this.translateService.instant('admin.warehouses.allAccessories.NONE')
    );

    // pattern
    const patternSufficient = unifyPhraseString(
      this.translateService.instant('admin.warehouses.patterns.SUFFICIENT')
    );
    const patternBald = unifyPhraseString(
      this.translateService.instant('admin.warehouses.patterns.BALD')
    );

    // additionalInfo
    const additionalInfoRepair = unifyPhraseString(
      this.translateService.instant('admin.warehouses.additionalInfo.REPAIR')
    );
    const additionalInfoPreparedNextSeason = unifyPhraseString(
      this.translateService.instant(
        'admin.warehouses.additionalInfo.PREPARED_NEXT_SEASON'
      )
    );

    for (let phraseChunk of phraseChunks) {
      phraseChunk = unifyPhraseString(phraseChunk);

      // state
      if (phraseChunk === stateActive) {
        phraseChunk = 'ACTIVE';
      }
      if (phraseChunk === stateInactive) {
        phraseChunk = 'INACTIVE';
      }

      // diskType
      if (phraseChunk === diskTypeNone) {
        phraseChunk = 'NONE';
      }
      if (phraseChunk === diskTypeTinny) {
        phraseChunk = 'TINNY';
      }
      if (phraseChunk === diskTypeAlu) {
        phraseChunk = 'ALU';
      }

      // tireType
      if (phraseChunk === tireTypeWinter) {
        phraseChunk = 'WINTER';
      }
      if (phraseChunk === tireTypeSummer) {
        phraseChunk = 'SUMMER';
      }
      if (phraseChunk === tireTypeBoth) {
        phraseChunk = 'BOTH';
      }

      // accessories
      if (phraseChunk === accessoriesWheelScrews) {
        phraseChunk = 'WHEEL_SCREWS';
      }
      if (phraseChunk === accessoriesSafetyScrew) {
        phraseChunk = 'SAFETY_SCREW';
      }
      if (phraseChunk === accessoriesCover) {
        phraseChunk = 'COVER';
      }
      if (phraseChunk === accessoriesPressureSensors) {
        phraseChunk = 'PRESSURE_SENSORS';
      }
      if (phraseChunk === accessoriesSpacerRings) {
        phraseChunk = 'SPACER_RINGS';
      }
      if (phraseChunk === accessoriesMatrix) {
        phraseChunk = 'MATRIX';
      }
      if (phraseChunk === accessoriesNone) {
        phraseChunk = 'NONE';
      }

      // pattern
      if (phraseChunk === patternSufficient) {
        phraseChunk = 'SUFFICIENT';
      }
      if (phraseChunk === patternBald) {
        phraseChunk = 'BALD';
      }

      // additionalInfo
      if (phraseChunk === additionalInfoRepair) {
        phraseChunk = 'REPAIR';
      }
      if (phraseChunk === additionalInfoPreparedNextSeason) {
        phraseChunk = 'PREPARED_NEXT_SEASON';
      }

      phraseResult = `${phraseResult},${phraseChunk}`.trim();
    }

    return phraseResult;
  }
}
