export const environment = {
  firebase: {
    apiKey: 'AIzaSyAmJc3KOYPpU8-9RXLIon7XZcxT0dZJw9w',
    authDomain: 'pneusystem-test.firebaseapp.com',
    databaseURL:
      'https://pneusystem-test-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'pneusystem-test',
    storageBucket: 'pneusystem-test.appspot.com',
    messagingSenderId: '559240825293',
    appId: '1:559240825293:web:4af6c519f8ffc81a6c5c84'
  },
  production: false,
  baseUrl: 'https://test.admin.pneusystem.cz',
  apiUrl: 'https://europe-west3-pneusystem-test.cloudfunctions.net/app',
  recaptcha: '6LdYwUQmAAAAAPXuqfXj6kzTMUR_1I_K36zSE94B',
  gaCode: '',
  stripeProductId: 'price_1PARJoLaM9T6rNYllqtUkoYz',
  stripePublic:
    'pk_test_51N02EpLaM9T6rNYlKnoTTGnTwN44zz1awOf7gXzjyesTAg67JL5KySwWLuZKoWSFmhaQN5wvaBxnjTIc0cp0CFvG00yYE4Sw1a'
};

import 'zone.js/plugins/zone-error';
