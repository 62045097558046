<form [formGroup]="warehouseForm" class="warehouse-form">
  <mat-form-field  class="w-full">
    <mat-label>{{'admin.warehouse-selector.warehouse' | translate}}</mat-label>
    <input
      type="text"
      id="warehouse"
      [placeholder]="'admin.warehouse-selector.placeholder' | translate"
      matInput
      [formControl]="warehouseFormControl"
      [matAutocomplete]="auto"
    >
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <div matSuffix style="display:flex">
      <button
        mat-icon-button
        (click)="$event.stopPropagation(); openEditWarehouseDialog()"
        [matTooltip]="'breadcrumbs.warehouseEdit' | translate"
        *ngIf="!loading && customerId && customerCarUid && selectedWarehouse">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="$event.stopPropagation(); openNewWarehouseDialog()"
        [matTooltip]="'breadcrumbs.warehouseNew' | translate"
        *ngIf="!loading && customerId && customerCarUid">
        <mat-icon>add</mat-icon>
      </button>
      <mat-spinner matSuffix [diameter]="18" *ngIf="loading" class="right-static-suffix"></mat-spinner>
    </div>
  </mat-form-field>

  <div
    *ngIf="customerCarUid && customerId && !allWarehouses.length"
  >
    <span class="mr-1">Nenalezeno</span>
    <button mat-button (click)="showAllWarehouses()">
      {{'admin.warehouse-selector.show-all-warehouses' | translate}}
    </button>
  </div>
</form>
