<div class="banner-wrapper" [class]="'type-' + BannerType[bannerType]">
  <div class="banner-content">
    <div class="icon">
      <mat-icon class="mr-1" *ngIf="icon">
        {{icon}}
      </mat-icon>
    </div>

    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>

