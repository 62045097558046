import { Component, Input } from '@angular/core';

export enum BannerType {
  INFO,
  ALERT,
  WARNING,
  SUCCESS,
  SECURE
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  public BannerType = BannerType;
  @Input() public bannerType: BannerType;

  public get icon(): string | null {
    switch (this.bannerType) {
      case BannerType.INFO:
        return 'info';

      case BannerType.WARNING:
        return 'warning';

      case BannerType.ALERT:
        return 'error';

      case BannerType.SUCCESS:
        return 'done';

      case BannerType.SECURE:
        return 'lock';

      default:
        return null;
    }
  }
}
