import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import {
  lastValueFrom,
  MonoTypeOperatorFunction,
  Observable,
  pipe
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppStateService } from '../app-state.service';

export interface HttpResponseProblem {
  status: number;
  messages: string;
  errors: string;
}

@Injectable()
export class RestService {
  public constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  public get<T>(path: string, httpOptions?: object): Promise<T> {
    return lastValueFrom(
      this.http
        .get<T>(this.url(path), {
          ...httpOptions
        })
        .pipe(this.retryOrTrow())
    );
  }

  public post<T>(
    path: string,
    body: unknown,
    httpOptions?: object
  ): Promise<T> {
    return lastValueFrom(
      this.http
        .post<T>(this.url(path), body, httpOptions)
        .pipe(this.retryOrTrow())
    );
  }

  public request<T>(request: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.http.request(request);
  }

  public put<T>(path: string, body: unknown, options?: object): Promise<T> {
    return lastValueFrom(
      this.http.put<T>(this.url(path), body, options).pipe(this.retryOrTrow())
    );
  }

  public delete<T>(path: string, options?: object): Promise<T> {
    return lastValueFrom(
      this.http.delete<T>(this.url(path), options).pipe(this.retryOrTrow())
    );
  }

  private retryOrTrow<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((problem) => {
        throw {
          status: problem.error.status,
          messages: problem.error.messages,
          errors: problem.error.errors
        } as HttpResponseProblem;
      })
    );
  }

  public url(path = ''): string {
    if (isDevMode()) {
      console.info('API call: ', path);
    }
    return `${environment.apiUrl}${path ? path : ''}`;
  }

  public get userUid(): string {
    return this.appStateService.userUid;
  }
}
