import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

interface Language {
  value: string;
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  public langForm: UntypedFormGroup;
  public languages: Language[] = [
    {
      value: 'cs'
    },
    {
      value: 'en'
    }
  ];
  public langControl = new UntypedFormControl(
    localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.languages[0].value
  );

  public constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.matIconRegistry.addSvgIcon(
      'cs',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg-country-flags/svg/cz.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'en',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg-country-flags/svg/gb.svg'
      )
    );

    this.langForm = new UntypedFormGroup({
      language: this.langControl
    });
  }

  public ngOnInit(): void {
    this.langForm.valueChanges.subscribe((value) => {
      this.translate.setDefaultLang(value.language);
      this.translate.use(value.language);
      localStorage.setItem('lang', value.language);
    });
  }
}
