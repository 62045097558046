import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AppBaseComponent } from '../../../app-base.component';
import { loadAresValues } from '../../../utils';
import { ApiService } from '@core/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface CustomerEditDialogData {
  customerId: number;
}

@Component({
  selector: 'app-selects-dialogs-edit-customer',
  templateUrl: './customer-edit-dialog.component.html',
  styleUrls: ['./customer-edit-dialog.component.scss']
})
export class CustomerEditDialogComponent
  extends AppBaseComponent
  implements OnInit
{
  public editCustomerForm: UntypedFormGroup;

  public idFormControl = new UntypedFormControl('', []);
  public firstNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public lastNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public phoneFormControl = new UntypedFormControl('', []);
  public emailFormControl = new UntypedFormControl('', [Validators.email]);
  public addressFormControl = new UntypedFormControl('', []);
  public cityFormControl = new UntypedFormControl('', []);
  public zipFormControl = new UntypedFormControl('', []);
  public inFormControl = new UntypedFormControl('', []);
  public tinFormControl = new UntypedFormControl('', []);
  public companyNameFormControl = new UntypedFormControl('', []);

  public aresLoading = false;

  public constructor(
    public dialogRef: MatDialogRef<CustomerEditDialogComponent>,
    private apiService: ApiService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: CustomerEditDialogData
  ) {
    super(injector);
    this.editCustomerForm = new UntypedFormGroup({
      id: this.idFormControl,
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      email: this.emailFormControl,
      address: this.addressFormControl,
      city: this.cityFormControl,
      zip: this.zipFormControl,
      in: this.inFormControl,
      tin: this.tinFormControl,
      companyName: this.companyNameFormControl,
      phone: this.phoneFormControl
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      const resp = await this.apiService.client.customers.getDetail.query(
        Number(this.data.customerId)
      );

      this.editCustomerForm.patchValue(resp);
    } catch (e) {
      console.error(e);

      this.operationFailedSnackbar(e.error.message);

      this.loading = false;
    }
  }

  public async onEditCustomer(): Promise<void> {
    if (this.editCustomerForm.valid) {
      this.loading = true;

      try {
        await this.apiService.client.customers.update.mutate(
          this.editCustomerForm.value
        );

        this.loading = false;
        this.operationSuccessSnackbar('admin.customers.editCustomerSuccess');
        this.dialogRef.close('done');
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }

  public async loadAresValuesClick(): Promise<void> {
    this.aresLoading = true;

    await loadAresValues(
      this.notificationService,
      this.translateService,
      this.editCustomerForm,
      this.apiService
    );

    this.aresLoading = false;
  }
}
