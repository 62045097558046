import { Component, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointsService } from '@core/breakpoints.service';
import { Location } from '@angular/common';
import { Sort, SortDirection } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppStateService } from '@core/app-state.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BasicFilter } from '@appTypes/filters.interface';

@Component({
  template: ''
})
export class AppBaseComponent {
  public onDestroy = new Subject<void>();
  public sortValue: Sort;
  public device: 'phone' | 'tablet' | 'desktop';
  public loading = false;
  public dataLoading = true;
  public dataLength = 0;
  private _injector: Injector;
  public notificationService: SnackBarNotificationService;
  public route: ActivatedRoute;
  public router: Router;
  public titleService: Title;
  public translateService: TranslateService;
  public breakpointsService: BreakpointsService;
  public appStateService: AppStateService;
  public dataSource = new MatTableDataSource<any>([]);
  public componentName = 'AppBaseComponent';
  public location: Location;
  public _displayedColumns: string[] = [];

  public get persistedFilter(): string {
    const persistValue = sessionStorage.getItem(`${this.componentName}-filter`);

    if (persistValue) {
      return persistValue;
    }

    return '';
  }

  public get tabIndex(): number {
    const persistValue = sessionStorage.getItem(`${this.componentName}-tab`);

    if (persistValue) {
      return Number(persistValue);
    }

    return 0;
  }

  public set tabIndex(index) {
    sessionStorage.setItem(`${this.componentName}-tab`, String(index));
  }

  public get displayedColumns(): string[] {
    const persistValue = localStorage.getItem(`${this.componentName}-columns`);
    if (persistValue) {
      return JSON.parse(persistValue);
    }

    return [];
  }

  public set displayedColumns(columns: string[]) {
    localStorage.setItem(
      `${this.componentName}-columns`,
      JSON.stringify(columns)
    );
  }

  public get persistedSort(): string {
    const persistValue = sessionStorage.getItem(`${this.componentName}-sort`);

    if (persistValue) {
      return persistValue;
    }

    if (this.componentName === 'TaskListTableComponent') {
      return 'priority';
    }

    if (this.componentName === 'WarehouseListComponent') {
      return 'stockUnstockDate';
    }

    return '';
  }

  public set persistedSort(column: string) {
    sessionStorage.setItem(`${this.componentName}-sort`, column);
  }

  public get persistedSortDirection(): SortDirection {
    const persistValue = sessionStorage.getItem(
      `${this.componentName}-sort-direction`
    );

    if (persistValue) {
      return <'asc' | 'desc' | ''>persistValue;
    }

    if (
      this.componentName === 'TaskListTableComponent' ||
      this.componentName === 'WarehouseListComponent'
    ) {
      return 'desc';
    }

    return '';
  }

  public set persistedSortDirection(direction: SortDirection) {
    sessionStorage.setItem(`${this.componentName}-sort-direction`, direction);
  }

  public get showTour(): string {
    const persistValue = localStorage.getItem(`${this.componentName}-tour`);

    if (persistValue) {
      return persistValue;
    }

    return 'show';
  }

  public constructor(injector: Injector) {
    this._injector = injector;
    this.titleService = this._injector.get(Title);
    this.translateService = this._injector.get(TranslateService);
    this.breakpointsService = this._injector.get(BreakpointsService);
    this.location = this._injector.get(Location);
    this.appStateService = this._injector.get(AppStateService);
    this.route = this._injector.get(ActivatedRoute);
    this.router = this._injector.get(Router);
    this.notificationService = this._injector.get(SnackBarNotificationService);

    this.breakpointsService.device$.subscribe((device) => {
      this.device = device;
    });
  }

  public onSortChanged(event: Sort): void {
    this.persistedSort = event.active;
    this.persistedSortDirection = event.direction;
  }

  public setTitle(key = ''): void {
    const translatedKey = this.translateService.instant(key);

    if (key) {
      this.titleService.setTitle(
        `${
          translatedKey ? translatedKey : key
        } • ${this.translateService.instant('global.projectName')}`
      );
    } else {
      this.titleService.setTitle(
        this.translateService.instant('global.projectName')
      );
    }
  }
  public operationFailedSnackbar(title: string, message = ''): void {
    this.notificationService.showError(
      this.translateService.instant(title),
      message
    );
  }

  public operationSuccessSnackbar(title: string, message = ''): void {
    this.notificationService.showSuccess(
      this.translateService.instant(title),
      message
    );
  }

  public applyFilter(value: string, paginator: MatPaginator) {
    sessionStorage.setItem(`${this.componentName}-filter`, value);
    paginator.firstPage();
  }

  public clearFilter(paginator: MatPaginator): void {
    sessionStorage.removeItem(`${this.componentName}-filter`);
    paginator.firstPage();
  }

  public goBack(): void {
    this.location.historyGo(-1);
  }

  public get userUid(): string {
    return this.appStateService.userUid;
  }

  public getFilter(
    paginator: MatPaginator,
    phrase = '',
    customerId: null | number = null,
    archived: boolean | null = null,
    order: string | null = null
  ): BasicFilter {
    return {
      limit: paginator.pageSize,
      offset: paginator.pageSize * paginator.pageIndex,
      phrase,
      customerId: customerId ? customerId : null,
      archived,
      order
    };
  }

  public toggleColumn($event: MouseEvent, column: string): void {
    $event.stopPropagation();

    if (!this._displayedColumns.length) {
      this._displayedColumns = [...this.displayedColumns];
    }

    if (this._displayedColumns.includes(column)) {
      this._displayedColumns.splice(this._displayedColumns.indexOf(column), 1);
    } else {
      this._displayedColumns.unshift(column);
    }
  }

  public dropColumn(event: CdkDragDrop<string[]>) {
    this._displayedColumns = [...this.displayedColumns];
    moveItemInArray(
      this._displayedColumns,
      event.previousIndex,
      event.currentIndex
    );

    this.updateDisplayedColumns();
  }

  public updateDisplayedColumns(): void {
    if (this._displayedColumns.length) {
      this.displayedColumns = [...this._displayedColumns];
    }
  }

  public async refreshData(): Promise<boolean> {
    return await this.router.navigateByUrl(this.router.url);
  }
}
