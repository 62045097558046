import { Injectable } from '@angular/core';
import { SnackBarNotificationComponent } from './snack-bar-notification.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

export type SnackBarType = 'error' | 'success' | 'info';
export interface SnackBarData {
  type: SnackBarType;
  title: string;
  description: string;

  close?: VoidFunction;
}

export const SNACK_BAR_DURATION_MS = 7700;

@Injectable()
export class SnackBarNotificationService {
  public constructor(private snackBar: MatSnackBar) {}

  public showError(title: string, description = ''): void {
    this.openSnackBar({ type: 'error', title, description });
  }

  public showInfo(title: string, description = ''): void {
    this.openSnackBar({ type: 'info', title, description });
  }

  public showSuccess(title: string, description = ''): void {
    this.openSnackBar({ type: 'success', title, description });
  }

  private openSnackBar(data: SnackBarData): void {
    const self = this.snackBar.openFromComponent(
      SnackBarNotificationComponent,
      {
        duration: SNACK_BAR_DURATION_MS,
        verticalPosition: 'top',
        data: {
          type: data.type,
          title: data.title,
          description: data.description,
          close: (): void => self.dismiss()
        }
      } as MatSnackBarConfig<SnackBarData>
    );
  }
}
