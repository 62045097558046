import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppBaseComponent } from '../../../app-base.component';

export interface BusinessHours {
  mondayEnd?: string;
  mondayStart?: string;
  tuesdayEnd?: string;
  tuesdayStart?: string;
  wednesdayEnd?: string;
  wednesdayStart?: string;
  thursdayEnd?: string;
  thursdayStart?: string;
  fridayEnd?: string;
  fridayStart?: string;
  saturdayEnd?: string;
  saturdayStart?: string;
  sundayEnd?: string;
  sundayStart?: string;
}

@Component({
  selector: 'app-business-hours-selector',
  templateUrl: './business-hours-selector.component.html',
  styleUrls: ['./business-hours-selector.component.scss']
})
export class BusinessHoursSelectorComponent
  extends AppBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public defaultStartTime: string;
  @Input() public defaultEndTime: string;
  @Output() public onChange = new EventEmitter<BusinessHours>();
  @Input() public set initialBusinessHours(businessHours: BusinessHours) {
    if (businessHours) {
      this.businessHoursForm.setValue(businessHours, { emitEvent: false });
    }
  }

  public businessHoursForm: UntypedFormGroup;

  public mondayBusinessHoursStartFormControl = new UntypedFormControl('');
  public mondayBusinessHoursEndFormControl = new UntypedFormControl('');
  public tuesdayBusinessHoursStartFormControl = new UntypedFormControl('');
  public tuesdayBusinessHoursEndFormControl = new UntypedFormControl('');
  public wednesdayBusinessHoursStartFormControl = new UntypedFormControl('');
  public wednesdayBusinessHoursEndFormControl = new UntypedFormControl('');
  public thursdayBusinessHoursStartFormControl = new UntypedFormControl('');
  public thursdayBusinessHoursEndFormControl = new UntypedFormControl('');
  public fridayBusinessHoursStartFormControl = new UntypedFormControl('');
  public fridayBusinessHoursEndFormControl = new UntypedFormControl('');
  public saturdayBusinessHoursStartFormControl = new UntypedFormControl('');
  public saturdayBusinessHoursEndFormControl = new UntypedFormControl('');
  public sundayBusinessHoursStartFormControl = new UntypedFormControl('');
  public sundayBusinessHoursEndFormControl = new UntypedFormControl('');

  private subscriptions: Subscription[] = [];

  public constructor(private injector: Injector) {
    super(injector);
    this.businessHoursForm = new UntypedFormGroup({
      mondayStart: this.mondayBusinessHoursStartFormControl,
      mondayEnd: this.mondayBusinessHoursEndFormControl,
      tuesdayStart: this.tuesdayBusinessHoursStartFormControl,
      tuesdayEnd: this.tuesdayBusinessHoursEndFormControl,
      wednesdayStart: this.wednesdayBusinessHoursStartFormControl,
      wednesdayEnd: this.wednesdayBusinessHoursEndFormControl,
      thursdayStart: this.thursdayBusinessHoursStartFormControl,
      thursdayEnd: this.thursdayBusinessHoursEndFormControl,
      fridayStart: this.fridayBusinessHoursStartFormControl,
      fridayEnd: this.fridayBusinessHoursEndFormControl,
      saturdayStart: this.saturdayBusinessHoursStartFormControl,
      saturdayEnd: this.saturdayBusinessHoursEndFormControl,
      sundayStart: this.sundayBusinessHoursStartFormControl,
      sundayEnd: this.sundayBusinessHoursEndFormControl
    });
  }

  public ngOnInit(): void {
    const valueSubscription = this.businessHoursForm.valueChanges.subscribe(
      () => {
        this.onChange.emit(this.businessHoursForm.getRawValue());
      }
    );

    if (this.defaultStartTime) {
      this.mondayBusinessHoursStartFormControl.setValue(this.defaultStartTime);
      this.tuesdayBusinessHoursStartFormControl.setValue(this.defaultStartTime);
      this.wednesdayBusinessHoursStartFormControl.setValue(
        this.defaultStartTime
      );
      this.thursdayBusinessHoursStartFormControl.setValue(
        this.defaultStartTime
      );
      this.fridayBusinessHoursStartFormControl.setValue(this.defaultStartTime);
      this.saturdayBusinessHoursStartFormControl.setValue(
        this.defaultStartTime
      );
      this.sundayBusinessHoursStartFormControl.setValue(this.defaultStartTime);
    }

    if (this.defaultEndTime) {
      this.mondayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.tuesdayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.wednesdayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.thursdayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.fridayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.saturdayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
      this.sundayBusinessHoursEndFormControl.setValue(this.defaultEndTime);
    }

    this.subscriptions.push(valueSubscription);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public toggleOpenState(
    startControl: UntypedFormControl,
    endControl: UntypedFormControl
  ) {
    if (startControl.enabled) {
      startControl.setValue(null);
      endControl.setValue(null);
    } else {
      startControl.setValue('07:00');
      endControl.setValue('16:00');
    }
    startControl.disabled ? startControl.enable() : startControl.disable();
    endControl.disabled ? endControl.enable() : endControl.disable();
  }
}
