import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SnackBarData, SnackBarType } from './snack-bar-notification.service';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-notification',
  templateUrl: './snack-bar-notification.component.html',
  styleUrls: ['./snack-bar-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarNotificationComponent {
  public constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  public get type(): SnackBarType {
    return this.data.type;
  }

  public close() {
    this.data.close?.();
  }
}
