import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
export interface NominatimResponse {
  lat: string;
  lon: string;
}
import type { BackendRouter } from '../../../../../pneusystem-api/functions/src';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public client = createTRPCProxyClient<BackendRouter>({
    links: [
      httpBatchLink({
        url: environment.apiUrl,
        async headers() {
          const token = localStorage.getItem('idToken');

          if (!token) {
            return {};
          }

          return {
            authorization: `Bearer ${token}`
          };
        }
      })
    ]
  });

  public constructor(private httpClient: HttpClient) {}

  public addressToGPS(
    address: string,
    city: string,
    zip: string
  ): Promise<NominatimResponse[]> {
    return lastValueFrom(
      this.httpClient.get<NominatimResponse[]>(
        `https://nominatim.openstreetmap.org/search?city=${city}&format=json&postalcode=${zip}&street=${address}&limit=1&countrycodes=cz,sk`
      )
    );
  }
}
