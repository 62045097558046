<mat-form-field  class="w-full">
  <mat-label>{{'admin.customer-selector.customer' | translate}}</mat-label>
  <mat-select required [formControl]="valueFormControl" #selectEl>
    <mat-option>
      <ngx-mat-select-search
        [clearSearchInput]="false"
        ngModel
        (ngModelChange)="filterChange($event)"
        [placeholderLabel]="'global.search' | translate"
        [noEntriesFoundLabel]="'global.notFound' | translate"
        [searching]="this.loading"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngIf="defaultSelectValue" [value]="defaultSelectValue.key" class="additional-info-option">
      <div class="flex items-center justify-between">
        <span>{{ defaultSelectValue.label }}</span>
        <span class="mat-small" *ngIf="selectEl.panelOpen">{{ defaultSelectValue.additionalInfo }}</span>
      </div>
    </mat-option>

    <mat-option *ngFor="let item of selectValues" [value]="item.key" class="additional-info-option">
      <div class="flex items-center justify-between">
        <span>{{ item.label }}</span>
        <span class="mat-small" *ngIf="selectEl.panelOpen">{{ item.additionalInfo }}</span>
      </div>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="valueFormControl.hasError('required')">
    {{'global.requiredValue' | translate}}
  </mat-error>

  <div matSuffix style="display: flex">
    <button
      type="button"
      mat-icon-button
      (click)="$event.stopPropagation(); openEditCustomerDialog()"
      [matTooltip]="'admin.customers.editCustomer' | translate"
      *ngIf="!loading && valueFormControl.value"
    >
      <mat-icon>edit</mat-icon>
    </button>

    <button
      type="button"
      mat-icon-button
      (click)="$event.stopPropagation(); openNewCustomerDialog()"
      [matTooltip]="'admin.customers.addCustomer' | translate"
      *ngIf="!loading"
    >
      <mat-icon>add</mat-icon>
    </button>

    <mat-spinner
      matSuffix
      [diameter]="18"
      *ngIf="loading"
      class="right-static-suffix"
    ></mat-spinner>
  </div>
</mat-form-field>
