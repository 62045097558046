import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbItem } from '../ui/breadcrumbs/breadcrumbs.component';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public breadcrumbItem: BreadcrumbItem;

  public constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) {
    this.router.events
      .pipe(
        filter((routeEvent) => routeEvent instanceof NavigationEnd),
        map(() => route),
        map((activatedRoute) => activatedRoute.firstChild),
        switchMap((firstChild) => (firstChild ? firstChild.data : of({})))
      )
      .subscribe((data: any) => {
        if (data.breadcrumb) {
          this.breadcrumbItem = data.breadcrumb;
        } else {
          this.breadcrumbItem = {
            path: '',
            label: ''
          };
        }
      });
  }

  public ngOnInit() {
    this.getNotifications().subscribe((data) => {
      console.log(data);
    });
  }

  public getNotifications(): Observable<any[]> {
    return this.firestore.collection('notifications').snapshotChanges();
  }

  public async logout(): Promise<void> {
    await this.authService.logout();
  }

  public getBranchUrl(): string {
    const account = this.route.snapshot.data['account'];
    return `${account.uid}`;
  }
}
