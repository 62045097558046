import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SelectorBaseComponent } from '../selector-base.component';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CustomerNewDialogComponent } from '../../dialogs/customer-new-dialog/customer-new-dialog.component';
import { CustomerEditDialogComponent } from '../../dialogs/customer-edit-dialog/customer-edit-dialog.component';
import { Customer } from '@appTypes/customer.interface';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public customerId: number;
  @Output() public onSelect = new EventEmitter<number>();
  public valueFormControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  public constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.provideDefaultValue(this.customerId);
    await this.fetchData();

    this.filterFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy), debounceTime(1000))
      .subscribe(async () => {
        await this.fetchData();
      });

    this.valueFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        this.customerId = val;
        this.onSelect.emit(val);
      });
  }

  public async fetchData(): Promise<void> {
    try {
      this.loading = true;

      const customerBundle =
        await this.apiService.client.customers.getList.query({
          phrase: this.filterFormControl.value,
          limit: 25,
          archived: false
        });
      this.selectValues.length = 0;

      for (const customer of customerBundle.data) {
        this.selectValues.push({
          label: <string>customer.customerFullName,
          key: customer.id.toString(),
          additionalInfo: this.generateAdditionalInfo(customer)
        });
      }

      this.valueFormControl.setValue(this.customerId, { emitEvent: false });
      this.onSelect.emit(this.customerId);
      this.loading = false;
    } catch (e) {
      // TODO error handling
      console.error(e);
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public async provideDefaultValue(key: number): Promise<void> {
    if (key) {
      const response = await this.apiService.client.customers.getDetail.query(
        Number(key)
      );
      this.defaultSelectValue = {
        key,
        label: <string>response.customerFullName,
        additionalInfo: this.generateAdditionalInfo(response)
      };
    }
  }

  public openNewCustomerDialog(): void {
    const dialog = this.dialog.open(CustomerNewDialogComponent, {
      width: '600px'
    });

    dialog.afterClosed().subscribe(async (res) => {
      if (res) {
        this.customerId = res.id;
        await this.fetchData();
        await this.provideDefaultValue(this.valueFormControl.value);
      }
    });
  }

  public openEditCustomerDialog(): void {
    const dialog = this.dialog.open(CustomerEditDialogComponent, {
      width: '600px',
      data: {
        customerId: this.valueFormControl.value
      }
    });

    dialog.afterClosed().subscribe(async (res) => {
      if (res === 'done') {
        await this.provideDefaultValue(this.valueFormControl.value);
      }
    });
  }

  protected generateAdditionalInfo(customer: Customer): string {
    let additionalInfo = '';

    if (customer.email) {
      additionalInfo += customer.phone
        ? `${customer.email} • `
        : customer.email;
    }

    additionalInfo += customer.phone ? customer.phone : '';
    return additionalInfo;
  }
}
