<form [formGroup]="langForm" class="lang-form">
  <mat-form-field appearance="outline">
    <mat-select [formControl]="langControl">
      <mat-select-trigger class="flex items-center">
        <mat-icon class="mr-1" [svgIcon]="langForm.value.language"></mat-icon>
      </mat-select-trigger>
      <mat-option value="cs">
        <mat-icon svgIcon="cs"></mat-icon>
      </mat-option>

      <mat-option value="en">
        <mat-icon svgIcon="en"></mat-icon>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
