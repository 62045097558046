<div class="notification" [ngClass]="type">
  <div class="notification__content">
    <ng-container *ngIf="type === 'success'">
      <mat-icon class="type">
        done
      </mat-icon>
    </ng-container>
    <ng-container *ngIf="type === 'error'">
      <mat-icon class="type">
        error
      </mat-icon>
    </ng-container>
    <ng-container *ngIf="type === 'info'">
      <mat-icon class="type">
      info
    </mat-icon>
    </ng-container>
    <h1 [innerHTML]="data.title" class="text-lg"></h1>
    <p [innerHTML]="data.description"></p>

    <mat-icon class="close" (click)="close()">
      close
    </mat-icon>
  </div>
</div>
