import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppBaseComponent } from '../../../app-base.component';

export interface BreakHours {
  mondayBreakEnd?: string;
  mondayBreakStart?: string;
  tuesdayBreakEnd?: string;
  tuesdayBreakStart?: string;
  wednesdayBreakEnd?: string;
  wednesdayBreakStart?: string;
  thursdayBreakEnd?: string;
  thursdayBreakStart?: string;
  fridayBreakEnd?: string;
  fridayBreakStart?: string;
  saturdayBreakEnd?: string;
  saturdayBreakStart?: string;
  sundayBreakEnd?: string;
  sundayBreakStart?: string;
}

@Component({
  selector: 'app-break-hours-selector',
  templateUrl: './break-hours-selector.component.html',
  styleUrls: ['./break-hours-selector.component.scss']
})
export class BreakHoursSelectorComponent
  extends AppBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public defaultStartTime: string;
  @Input() public defaultEndTime: string;
  @Output() public onChange = new EventEmitter<BreakHours>();
  @Input() public set initialBreakHours(breakHours: BreakHours) {
    if (breakHours) {
      this.breakHoursForm.setValue(breakHours, { emitEvent: false });
    }
  }

  public breakHoursForm: UntypedFormGroup;

  public mondayBreakHoursStartFormControl = new UntypedFormControl('');
  public mondayBreakHoursEndFormControl = new UntypedFormControl('');
  public tuesdayBreakHoursStartFormControl = new UntypedFormControl('');
  public tuesdayBreakHoursEndFormControl = new UntypedFormControl('');
  public wednesdayBreakHoursStartFormControl = new UntypedFormControl('');
  public wednesdayBreakHoursEndFormControl = new UntypedFormControl('');
  public thursdayBreakHoursStartFormControl = new UntypedFormControl('');
  public thursdayBreakHoursEndFormControl = new UntypedFormControl('');
  public fridayBreakHoursStartFormControl = new UntypedFormControl('');
  public fridayBreakHoursEndFormControl = new UntypedFormControl('');
  public saturdayBreakHoursStartFormControl = new UntypedFormControl('');
  public saturdayBreakHoursEndFormControl = new UntypedFormControl('');
  public sundayBreakHoursStartFormControl = new UntypedFormControl('');
  public sundayBreakHoursEndFormControl = new UntypedFormControl('');

  private subscriptions: Subscription[] = [];

  public constructor(private injector: Injector) {
    super(injector);
    this.breakHoursForm = new UntypedFormGroup({
      mondayBreakStart: this.mondayBreakHoursStartFormControl,
      mondayBreakEnd: this.mondayBreakHoursEndFormControl,
      tuesdayBreakStart: this.tuesdayBreakHoursStartFormControl,
      tuesdayBreakEnd: this.tuesdayBreakHoursEndFormControl,
      wednesdayBreakStart: this.wednesdayBreakHoursStartFormControl,
      wednesdayBreakEnd: this.wednesdayBreakHoursEndFormControl,
      thursdayBreakStart: this.thursdayBreakHoursStartFormControl,
      thursdayBreakEnd: this.thursdayBreakHoursEndFormControl,
      fridayBreakStart: this.fridayBreakHoursStartFormControl,
      fridayBreakEnd: this.fridayBreakHoursEndFormControl,
      saturdayBreakStart: this.saturdayBreakHoursStartFormControl,
      saturdayBreakEnd: this.saturdayBreakHoursEndFormControl,
      sundayBreakStart: this.sundayBreakHoursStartFormControl,
      sundayBreakEnd: this.sundayBreakHoursEndFormControl
    });
  }

  public ngOnInit(): void {
    const valueSubscription = this.breakHoursForm.valueChanges.subscribe(() => {
      this.onChange.emit(this.breakHoursForm.getRawValue());
    });

    if (this.defaultStartTime) {
      this.mondayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.tuesdayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.wednesdayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.thursdayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.fridayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.saturdayBreakHoursStartFormControl.setValue(this.defaultStartTime);
      this.sundayBreakHoursStartFormControl.setValue(this.defaultStartTime);
    }

    if (this.defaultEndTime) {
      this.mondayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.tuesdayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.wednesdayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.thursdayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.fridayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.saturdayBreakHoursEndFormControl.setValue(this.defaultEndTime);
      this.sundayBreakHoursEndFormControl.setValue(this.defaultEndTime);
    }

    this.subscriptions.push(valueSubscription);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
