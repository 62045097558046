import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Customer } from '@appTypes/customer.interface';
import { CustomerCarEditDialogComponent } from '../../dialogs/customer-car-edit-dialog/customer-car-edit-dialog.component';
import { SelectorBaseComponent } from '../selector-base.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';

interface CustomerCarWithFullName {
  carName: string;
  customerCarUid: string;
}

@Component({
  selector: 'app-customer-car-selector',
  templateUrl: './customer-car-selector.component.html',
  styleUrls: ['./customer-car-selector.component.scss']
})
export class CustomerCarSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public customerCarFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public filteredOptions: Observable<string[]>;
  public customerCarForm: UntypedFormGroup;
  public customer: Customer;
  public selectedCar: CustomerCarWithFullName;
  private customerCarsWithFullName: CustomerCarWithFullName[] = [];
  private _customerId: number;
  @Output() public onChange = new EventEmitter<string | null>();
  @Input() public set customerId(customerId: number) {
    if (customerId) {
      this._customerId = customerId;
      this.customerCarForm.patchValue({
        customerCar: ''
      });
      this.onChange.emit(null);
      this.fetchData();
    }
  }

  public get customerId(): number {
    return this._customerId;
  }

  private _initialCustomerCarUid: string;
  @Input() public set initialCustomerCarUid(customerCarUid: string) {
    if (customerCarUid) {
      this._initialCustomerCarUid = customerCarUid;
      this.fetchData();
    }
  }
  public get initialCustomerCarUid(): string {
    return this._initialCustomerCarUid;
  }

  public constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    super();
    this.customerCarForm = new UntypedFormGroup({
      customerCar: this.customerCarFormControl
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchData();

    this.customerCarForm.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        const customerCar = this.customerCarsWithFullName.find(
          (x) => x.carName === value.customerCar
        );

        if (customerCar) {
          this.onChange.emit(customerCar.customerCarUid);
          this.selectedCar = customerCar;
        }
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const fullCarNames: string[] = [];
    const filteredFullNameCars = this.customerCarsWithFullName.filter((x) =>
      x.carName.toLowerCase().includes(filterValue)
    );
    for (const car of filteredFullNameCars) {
      fullCarNames.push(car.carName);
    }

    return fullCarNames;
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public async fetchData(): Promise<void> {
    if (this.customerId) {
      try {
        this.loading = true;
        this.customer = await this.apiService.client.customers.getDetail.query(
          Number(this.customerId)
        );

        this.customerCarsWithFullName.length = 0;

        if (this.customer.cars) {
          for (const customerCar of this.customer.cars) {
            const carName = customerCar.licencePlate
              ? `${customerCar.carBrandModel} • ${customerCar.licencePlate}`
              : customerCar.carBrandModel;
            this.customerCarsWithFullName.push({
              carName,
              customerCarUid: customerCar.uid
            });
          }
        }

        this.filteredOptions = this.customerCarFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );

        const customerCar = this.customerCarsWithFullName.find(
          (x) =>
            x.customerCarUid === this.initialCustomerCarUid ||
            x.customerCarUid === this.selectedCar?.customerCarUid
        );

        if (customerCar) {
          this.customerCarForm.patchValue({
            customerCar: customerCar.carName
          });
          this.onChange.emit(customerCar.customerCarUid);
        } else {
          this.customerCarForm.patchValue({
            customerCar: ''
          });
          this.onChange.emit(null);
        }

        this.loading = false;
      } catch (e) {
        // TODO error handling
        console.error(e);
        this.loading = false;
      }
    }
  }

  public openEditCustomerCarDialog(): void {
    const dialog = this.dialog.open(CustomerCarEditDialogComponent, {
      width: '600px',
      data: {
        customerId: this.customer.id
      }
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        if (res === 'done') {
          this.fetchData();
        }
      });
  }
}
