import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from '@core/services/api.service';
import _ from 'lodash';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { BusinessHours } from '@shared/selectors/business-hours-selector/business-hours-selector.component';
import { User } from '@appTypes/auth.interface';
import { BreakHours } from '@shared/selectors/break-hours-selector/break-hours-selector.component';

export const translatePriority = (
  priority: number,
  translate: TranslateService
): string => {
  let result = '';

  switch (priority) {
    case 0:
      result = translate.instant('global.low');
      break;
    case 1:
      result = translate.instant('global.medium');
      break;
    case 2:
      result = translate.instant('global.high');
      break;
  }

  return result;
};

export const translateState = (
  state: string,
  translate: TranslateService
): string => {
  let result = '';

  switch (state) {
    case 'ACTIVE':
      result = translate.instant('admin.warehouses.states.ACTIVE');
      break;
    case 'INACTIVE':
      result = translate.instant('admin.warehouses.states.INACTIVE');
      break;
    case 'ASSIGNED':
      result = translate.instant('admin.tasks.states.ASSIGNED');
      break;
    case 'IN_PROGRESS':
      result = translate.instant('admin.tasks.states.IN_PROGRESS');
      break;
    case 'WAITING_FOR_SUPPLIER':
      result = translate.instant('admin.tasks.states.WAITING_FOR_SUPPLIER');
      break;
    case 'WAITING_FOR_CUSTOMER':
      result = translate.instant('admin.tasks.states.WAITING_FOR_CUSTOMER');
      break;
    case 'NEED_TASK_SPECIFICATION':
      result = translate.instant('admin.tasks.states.NEED_TASK_SPECIFICATION');
      break;
    case 'DONE':
      result = translate.instant('admin.tasks.states.DONE');
      break;
    case 'ARCHIVED':
      result = translate.instant('admin.tasks.states.ARCHIVED');
      break;
  }

  return result;
};

export const deepEqual = (object1: object, object2: object): boolean => {
  if (!object1 || !object2) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const val1 = object1[key];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

function isObject(object: object): boolean {
  return object != null && typeof object === 'object';
}

export const loadAresValues = async (
  notificationService: SnackBarNotificationService,
  translate: TranslateService,
  form: UntypedFormGroup,
  apiService: ApiService
): Promise<void> => {
  if (form.value.in) {
    try {
      const aresData = await apiService.client.ares.query(form.value.in);

      form.patchValue(aresData);
    } catch (e) {
      console.error(e);

      notificationService.showError(
        translate.instant(`auth.signup.fillInFailed`)
      );
    }
  } else {
    notificationService.showError(translate.instant(`auth.signup.fillIn`));
  }
};

export const setBusinessHours = (user: User): BusinessHours => {
  return {
    mondayEnd: user.mondayEnd,
    mondayStart: user.mondayStart,
    tuesdayEnd: user.tuesdayEnd,
    tuesdayStart: user.tuesdayStart,
    wednesdayEnd: user.wednesdayEnd,
    wednesdayStart: user.wednesdayStart,
    thursdayEnd: user.thursdayEnd,
    thursdayStart: user.thursdayStart,
    fridayEnd: user.fridayEnd,
    fridayStart: user.fridayStart,
    saturdayEnd: user.saturdayEnd,
    saturdayStart: user.saturdayStart,
    sundayEnd: user.sundayEnd,
    sundayStart: user.sundayStart
  };
};

export const setBreakHours = (user: User): BreakHours => {
  return {
    mondayBreakEnd: user.mondayBreakEnd,
    mondayBreakStart: user.mondayBreakStart,
    tuesdayBreakEnd: user.tuesdayBreakEnd,
    tuesdayBreakStart: user.tuesdayBreakStart,
    wednesdayBreakEnd: user.wednesdayBreakEnd,
    wednesdayBreakStart: user.wednesdayBreakStart,
    thursdayBreakEnd: user.thursdayBreakEnd,
    thursdayBreakStart: user.thursdayBreakStart,
    fridayBreakEnd: user.fridayBreakEnd,
    fridayBreakStart: user.fridayBreakStart,
    saturdayBreakEnd: user.saturdayBreakEnd,
    saturdayBreakStart: user.saturdayBreakStart,
    sundayBreakEnd: user.sundayBreakEnd,
    sundayBreakStart: user.sundayBreakStart
  };
};

export const dayOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export const unifyPhraseString = (phrase: string): string => {
  return _.deburr(phrase.trim().toLowerCase());
};
