<div class="breadcrumbs-wrapper text-center-phone">
    <span *ngFor="let item of breadcrumbItems; index as i">

    <ng-container *ngIf="i + 1 < breadcrumbItems.length">
      <a [routerLink]="item.path" mat-button >
        <mat-icon>{{item.icon}}</mat-icon>
        {{item.label | translate}}
      </a>

      <span class="delimiter">/</span>
    </ng-container>

    <ng-container *ngIf="i + 1 === breadcrumbItems.length">
      <span class="ml-1">{{item.label | translate}}</span>
    </ng-container>
  </span>
</div>


