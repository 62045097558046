import { Component, Injector } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AppBaseComponent } from '../../../app-base.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'app-selects-dialogs-new-customer',
  templateUrl: './customer-new-dialog.component.html',
  styleUrls: ['./customer-new-dialog.component.scss']
})
export class CustomerNewDialogComponent extends AppBaseComponent {
  public addCustomerForm: UntypedFormGroup;

  public firstNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public lastNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public phoneFormControl = new UntypedFormControl('', []);
  public emailFormControl = new UntypedFormControl('', [Validators.email]);

  public constructor(
    public dialogRef: MatDialogRef<CustomerNewDialogComponent>,
    private apiService: ApiService,
    private injector: Injector
  ) {
    super(injector);
    this.addCustomerForm = new UntypedFormGroup({
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      email: this.emailFormControl,
      phone: this.phoneFormControl,
      cars: new UntypedFormArray([])
    });
  }

  public async onAddCustomer(): Promise<void> {
    if (this.addCustomerForm.valid) {
      this.loading = true;

      try {
        const customer = await this.apiService.client.customers.create.mutate(
          this.addCustomerForm.value
        );

        this.loading = false;
        this.operationSuccessSnackbar('admin.customers.addCustomerSuccess');
        this.dialogRef.close(customer);
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }
}
