import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { PersonalInfoResponse } from '@appTypes/profile.interface';
import { ApiService } from '@core/services/api.service';

@Injectable()
export class ProfilePersonalResolver {
  public constructor(
    private apiService: ApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(): Observable<PersonalInfoResponse> {
    return from(this.apiService.client.profile.getPersonalInfo.query()).pipe(
      catchError((e) => {
        this.notificationService.showError('Chyba');

        throw e;
      })
    );
  }
}
