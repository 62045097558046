<mat-toolbar color="primary" class="header">
  <mat-toolbar-row>
    <a routerLink="/" class="logo-container" *ngIf="showLogo">
      <img class="logo" src="/assets/logo-white.png" alt="PneuSYSTEM" width="200">
    </a>
    <span class="spacer"></span>

    <ng-container *ngIf="false">
      <mat-divider class="ml-2 mr-2" vertical></mat-divider>
      <app-language-selector></app-language-selector>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
