import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'app-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrl: './dialog-title.component.scss',
  standalone: true,
  imports: [CommonModule, CloseButtonComponent, MatIcon],
  encapsulation: ViewEncapsulation.None
})
export class DialogTitleComponent {
  @Input({ required: true }) public title: string;
  @Input({ required: true }) public icon: string;
  @Input() public disableCloseButton = false;
}
