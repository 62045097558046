import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SelectorBaseComponent } from '../selector-base.component';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'app-tire-manufacturer-selector',
  templateUrl: './tire-manufacturer-selector.component.html',
  styleUrls: ['./tire-manufacturer-selector.component.scss']
})
export class TireManufacturerSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public tireManufacturer: string;
  @Output() public onSelect = new EventEmitter<string>();
  public valueFormControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  public constructor(private apiService: ApiService) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.provideDefaultValue(this.tireManufacturer);
    await this.fetchData();

    this.filterFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy), debounceTime(1000))
      .subscribe(async () => {
        await this.fetchData();

        this.tireManufacturer = this.filterFormControl.value;
        this.valueFormControl.setValue(this.tireManufacturer);
        this.onSelect.emit(this.tireManufacturer);
        this.provideDefaultValue(this.tireManufacturer);
      });

    this.valueFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        this.tireManufacturer = val;
        this.onSelect.emit(val);
      });
  }

  public async fetchData(): Promise<void> {
    try {
      this.loading = true;

      const response = await this.apiService.client.tireManufacturers.query({
        phrase: this.filterFormControl.value,
        limit: 25
      });
      this.selectValues.length = 0;

      for (const item of response) {
        this.selectValues.push({
          label: item.manufacturer,
          key: item.manufacturer
        });
      }

      this.valueFormControl.setValue(this.tireManufacturer, {
        emitEvent: false
      });
      this.onSelect.emit(this.tireManufacturer);
      this.loading = false;
    } catch (e) {
      // TODO error handling
      console.error(e);
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
