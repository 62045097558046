<form [formGroup]="businessHoursForm" [class.phone]="device === 'phone'">
  <table class="w-full text-center">
    <tr>
      <td>
        {{'shortDays.mo' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="mondayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="mondayBusinessHoursEndFormControl">
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="mondayBusinessHoursStartFormControl.value && mondayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(mondayBusinessHoursStartFormControl, mondayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.tu' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="tuesdayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="tuesdayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="tuesdayBusinessHoursStartFormControl.value && tuesdayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(tuesdayBusinessHoursStartFormControl, tuesdayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.we' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="wednesdayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="wednesdayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="wednesdayBusinessHoursStartFormControl.value && wednesdayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(wednesdayBusinessHoursStartFormControl, wednesdayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.th' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="thursdayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="thursdayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="thursdayBusinessHoursStartFormControl.value && thursdayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(thursdayBusinessHoursStartFormControl, thursdayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.fr' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="fridayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="fridayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="fridayBusinessHoursStartFormControl.value && fridayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(fridayBusinessHoursStartFormControl, fridayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.sa' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="saturdayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="saturdayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="saturdayBusinessHoursStartFormControl.value && saturdayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(saturdayBusinessHoursStartFormControl, saturdayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{'shortDays.su' | translate}}
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="sundayBusinessHoursStartFormControl" >
        </mat-form-field>
      </td>
      <td>
        <span class="pl-1 pr-1">-</span>
      </td>
      <td>
        <mat-form-field class="w-full time-field" >
          <input type="time" matInput [formControl]="sundayBusinessHoursEndFormControl" >
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          class="ml-1"
          [checked]="sundayBusinessHoursStartFormControl.value && sundayBusinessHoursEndFormControl.value"
          (click)="$event.preventDefault(); toggleOpenState(sundayBusinessHoursStartFormControl, sundayBusinessHoursEndFormControl)"
        >
          {{'global.opened' | translate}}
        </mat-checkbox>
      </td>
    </tr>
  </table>
</form>
