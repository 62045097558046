<app-dialog-title
  [title]="'breadcrumbs.warehouseNew' | translate"
  icon="add"
/>

<form [formGroup]="addWarehouseForm" (ngSubmit)="onAddWarehouse()">
  <div mat-dialog-content>
    <div class="responsive-form">
      <mat-form-field  class="w-full">
        <mat-label>Pozice</mat-label>
        <input id="position" type="text" matInput [formControl]="positionFormControl">
      </mat-form-field>

      <mat-form-field  class="w-full">
        <mat-label>{{'global.state' | translate}}</mat-label>
        <mat-select [formControl]="stateFormControl">
          <mat-option value="ACTIVE">{{'admin.warehouses.states.ACTIVE' | translate}}</mat-option>
          <mat-option value="INACTIVE">{{'admin.warehouses.states.INACTIVE' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.price' | translate}}</mat-label>
        <input id="price" type="number" matInput [formControl]="priceFormControl">
      </mat-form-field>

      <mat-form-field  class="w-full">
        <mat-label>{{'admin.warehouses.dimension' | translate}}</mat-label>
        <input id="dimension" required type="text" matInput [formControl]="dimensionFormControl">
        <mat-error *ngIf="dimensionFormControl.hasError('required')">
          {{'global.requiredValue' | translate}}
        </mat-error>
      </mat-form-field>

      <app-tire-manufacturer-selector
        (onSelect)="onTireManufacturerSelect($event)"
      >
      </app-tire-manufacturer-selector>

      <mat-form-field  class="w-full">
        <mat-label>{{'admin.warehouses.diskType' | translate}}</mat-label>
        <mat-select [formControl]="diskTypeFormControl">
          <mat-option value="NONE">{{'admin.warehouses.diskTypes.NONE' | translate}}</mat-option>
          <mat-option value="TINNY">{{'admin.warehouses.diskTypes.TINNY' | translate}}</mat-option>
          <mat-option value="ALU">{{'admin.warehouses.diskTypes.ALU' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field  class="w-full">
        <mat-label>{{'admin.warehouses.numberOfPcs' | translate}}</mat-label>
        <input id="numberOfPcs" required type="text" matInput [formControl]="numberOfPcsFormControl">
        <mat-error *ngIf="numberOfPcsFormControl.hasError('required')">
          {{'global.requiredValue' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field  class="w-full">
        <mat-label>{{'admin.warehouses.tireType' | translate}}</mat-label>
        <mat-select [formControl]="tireTypeFormControl">
          <mat-option value="WINTER">{{'admin.warehouses.tireTypes.WINTER' | translate}}</mat-option>
          <mat-option value="SUMMER">{{'admin.warehouses.tireTypes.SUMMER' | translate}}</mat-option>
          <mat-option value="BOTH">{{'admin.warehouses.tireTypes.BOTH' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="flex justify-between">
    <button mat-raised-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>

    <button mat-raised-button color="primary" [disabled]="loading || !addWarehouseForm.valid" cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner></mat-spinner>
      </mat-icon>
      <mat-icon *ngIf="!loading">add</mat-icon>
      {{'admin.warehouses.addWarehouseSubmit' | translate}}
    </button>
  </div>
</form>

